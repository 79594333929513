import React from "react"
import Img from 'gatsby-image';

import * as styles from "./styles"

const CaseContainer = ({url, title, image, children}) => (
    <div css={styles.caseContainer}>
      <div>
        <h3>{title}</h3>
        {url && <a css={styles.seeLive} href={url} target="_blank" rel="noreferrer">See live</a>}
      </div>
      {children}
      <div css={styles.caseImage}>
        {url ? (
          <a href={url} target="_blank" rel="noreferrer">
            <Img fluid={image} />
          </a>
        ) : (
          <Img fluid={image} />
        )}
      </div>
    </div>
)

export default CaseContainer
