import React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import SEO from "../components/seo"
import CaseContainer from "../components/caseContainer"
import SectionTitle from "../components/sectionTitle"

import * as styles from "../styles/index.css"

const IndexPage = props => (
  <Layout professional={true} >
  <SEO title="Kevin Ng" />
    <SectionTitle>About</SectionTitle>
    <div css={styles.textContainer}>
      <p>I am a developer and designer in Toronto currently working as a Senior Frontend Engineer at <a href="https://www.shopify.com/" target="blank">Shopify</a>.</p>
      <p>I focus mostly on front-end development and love creating visually interesting, beautiful and functional front ends.</p>
    </div>
    <SectionTitle>Current Work</SectionTitle>
    <CaseContainer
      title="Shopify Balance"
      url="https://www.shopify.com/balance"
      image={props.data.shopifyBalance.childImageSharp.fluid}
    >
      <ul>
        <li>Shopify</li>
        <li>2022</li>
      </ul>
    </CaseContainer>
    <CaseContainer
      title="Shopify Credit"
      url="https://www.shopify.com/credit"
      image={props.data.shopifyCredit.childImageSharp.fluid}
    >
      <ul>
        <li>Shopify</li>
        <li>2023</li>
      </ul>
    </CaseContainer>  
    <SectionTitle>Selected Work</SectionTitle>
    <CaseContainer
      title="Ecobee"
      url="https://www.ecobee.com/"
      image={props.data.ecobee.childImageSharp.fluid}
    >
      <ul>
        <li>TWG</li>
        <li>2019</li>
      </ul>
    </CaseContainer>
    <CaseContainer
      title="Audi Canada"
      // url="https://reserve.audi.ca/"
      image={props.data.audi.childImageSharp.fluid}
    >
      <ul>
        <li>TWG</li>
        <li>2018</li>
      </ul>
    </CaseContainer>
    <CaseContainer
      title="Another Room"
      url="https://anotherroom.io/"
      image={props.data.anotherRoom.childImageSharp.fluid}
    >
      <ul>
        <li>Freelance</li>
        <li>2020</li>
      </ul>
    </CaseContainer>
    <CaseContainer
      title="Tonic Blooms"
      url="https://tonicblooms.com/"
      image={props.data.tonicBlooms.childImageSharp.fluid}
    >
      <ul>
        <li>Freelance</li>
        <li>2020</li>
      </ul>
    </CaseContainer>
    <CaseContainer
      title="Craig's Cookies"
      url="https://craigscookies.com/"
      image={props.data.craigsCookies.childImageSharp.fluid}
    >
      <ul>
        <li>Freelance</li>
        <li>2020</li>
      </ul>
    </CaseContainer>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    shopifyBalance: file(relativePath: { eq: "shopify-balance.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    shopifyCredit: file(relativePath: { eq: "shopify-credit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    ecobee: file(relativePath: { eq: "ecobee.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    anotherRoom: file(relativePath: { eq: "another-room.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    audi: file(relativePath: { eq: "audi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    tonicBlooms: file(relativePath: { eq: "tonic-blooms.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    craigsCookies: file(relativePath: { eq: "craigs-cookies.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
