import { css } from '@emotion/core'

export const textContainer = css`
  display: grid;
  width: 100%;
  min-height: 200px;
  margin-bottom: 64px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;

  p {
    font-size: 16px;
    line-height: 1.34;
    grid-row-start: 1;
  }

  @media(max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media(max-width: 768px) {
    min-height: 160px;
    margin-bottom: 32px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr 1fr 0.5fr;
  }

  @media(max-width: 480px) {
    display: block;
    min-height: none;

    p + p {
      margin-top: 16px;
    }
  }
`