import { css } from '@emotion/core';

export const caseContainer = css`
  display: grid;
  width: 100%;
  min-height: 240px;
  margin-bottom: 64px;
  border-bottom: 4px solid #2f2f2f;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;

  h3 {
    margin: 0;
    font-family: 'Rubik';
    font-size: 56px;
    line-height: 1;
    letter-spacing: -1px;
    text-transform: uppercase;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  ul {
    padding-top: 4px;
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    li {
      list-style-type: none;
    }
  }

  @media(max-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;

    ul {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
  }

  @media(max-width: 768px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    h3 {
      font-size: 40px;
    }

    ul {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }

  @media(max-width: 480px) {
    display: block;
    margin-bottom: 32px;
    padding-bottom: 0;
    border-bottom-width: 4px;

    h3 {
      font-size: 32px;
    }

    ul {
      padding: 0;
      margin: 0 0 16px;
    }
  }

  @media(prefers-color-scheme: dark) {
    border-bottom-color: #dcdcdc;
  }
`

export const caseImage = css`
  grid-row: 1 / 3;
  grid-column: 3 / -1;

  @media(max-width: 1280px) {
    grid-row: 1 / 3;
    grid-column: 2 / 4;
  }

  @media(max-width: 768px) {
    grid-row: 2 / 4;
    grid-column: 1 / 3;
  }
`

export const seeLive = css`
  font-family: 'Rubik';
  font-size: 24px;
  text-transform: uppercase;
`